import './src/styles/globals.css'
import Auth from '@aws-amplify/auth'
import { setUser } from './src/utils/auth'

export const onRouteUpdate = (state, page, pages) => {
    Auth.currentAuthenticatedUser()
        .then(user => {
            const userInfo = {
                ...user.attributes,
                username: user.username,
                groups: user.signInUserSession.idToken.payload['cognito:groups']
                    ? user.signInUserSession.idToken.payload['cognito:groups']
                    : [],
            }
            setUser(userInfo)
        })
        .catch(err => {
            window.localStorage.setItem('gatsbyUser', null)
        })
}
